<template>
    <div style="display: flex; align-items: baseline; gap: 12px">
        Access Token
        <input type="password" v-model="token"/>
        <Button 
            v-if="isAuthorized"
            @click="logout">
            Logout
        </Button>
        <Button 
            v-else="isAuthorized"
            @click="login">
            Login
        </Button>
    </div>
</template>

<script>
import Button from '@/components/Button.vue';
import Auth from '@/settings/Auth'

export default {
    components: {
        Button
    },

    data() {
        return {
            isAuthorized: false,
            token: null
        }
    },

    methods: {
        show() {
            this.token = Auth.token()
            this.isAuthorized = Auth.isAuthorized()
        },

        async login() {
            try {
                let res = await Auth.auth(this.token)

                Auth.saveToken(this.token)
                alert('Login successful')

                this.$router.push('/packs')
            } catch (error) {
                if (error.response.status === 403) {
                    alert("The token is invalid")
                    this.token = null
                } else {
                    alert(error)
                }
            }
        },
        
        logout() {
            Auth.removeToken()
            this.show()
        }
    },

    mounted() {
        this.show();
    }
}
</script>

<style>
</style>