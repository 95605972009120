import CommaSeparatedSplitter from "../composers/CommaSeparatedSplitter";

export default class BassSlotComposer {
    constructor(label) {
        this.label = label
    }

    compose(timbres) {
        const timbresArray = (new CommaSeparatedSplitter().split(this.string(timbres)))
        if (timbresArray.length == 0) {
            throw Error(`[${this.label}] at least 1 timbre must be specified`)
        }

        var compose = {
            timbres: timbresArray
        }

        return compose
    }

    string(value) {
        return value === undefined ? '' : value
    }
}