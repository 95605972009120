import axios from 'axios'
import Auth from '@/settings/Auth'

export default class PackSender {
    async send(body, update) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Auth.header()
        }

        var res
        if (update) {
            res = await axios.put(
                `/packs/${update.id}`,
                body,
                { headers: headers }
            )
        } else {
            res = await axios.post(
                `/packs/`,
                body,
                { headers: headers }
            )
        }

        return res
    }
}
