export default class BassSlotComposer {
    constructor(label) {
        this.label = label
    }

    validate(timbres, availableTimbres) {
        let failed = timbres.filter(function(timbre) {
            return !availableTimbres.includes(timbre)
        })

        if (failed.length > 0) {
            throw Error(`[${this.label}] Timbres ${failed} failed. Allowed timbres: ${availableTimbres}`)
        }
    }

    string(value) {
        return value === undefined ? '' : value
    }
}