export default class DelayConfigValidator {
    validate(dryWetMix, feedback, timeRate, lowPassCutoff, toReverbLevel) {
        if (!this.between(dryWetMix, 0, 100)) {
            throw new Error("dryWetMix is out of range")
        }

        if (!this.between(feedback, 0, 100)) {
            throw new Error("feedback is out of range")
        }

        if (!this.between(timeRate, 0.1, 60)) {
            throw new Error("timeRate is out of range")
        }

        if (!this.between(lowPassCutoff, 10, 22050)) {
            throw new Error("lowPassCutoff is out of range")
        }

        if (!this.between(toReverbLevel, 0, 100)) {
            throw new Error("toReverbLevel is out of range")
        }

        return true
    }

    between(x, min, max) {
        return x >= min && x <= max;
    }
}