import { createApp } from 'vue'
import axios from 'axios'
import App from "./App.vue"
import router from './router'

axios.defaults.baseURL = process.env.VUE_APP_BASE_BACK_URL;

const app = createApp(App)
app.use(router)
app.mount('#app')
