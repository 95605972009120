<template>
    <div class="inRow">
        <label class="tooltip">
            <slot name="label"></slot>
            <tooltip class="tooltiptext" v-if="$slots.tooltip">
                <slot name="tooltip"></slot>
            </tooltip>
        </label>
        <input 
            v-bind:value="value" 
            @input="changed" 
            type="number" 
            v-bind:min="min"
            v-bind:max="max"
            v-bind:step="step" />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: false,
            default: 0
        },
        min: {
            type: Number,
            required: false,
            default: 0
        },
        max: {
            type: Number,
            required: false,
            default: 0
        },
        step: {
            type: Number,
            required: false,
            default: 0
        }
    },

    methods: {
        changed(event) {
            this.$emit("valueChanged", Number(event.target.value))
        }
    }
}
</script>

<style scoped>
    .tooltip {
        position: relative;
        display: inline-block;
    }

    .tooltip .tooltiptext {
        visibility: hidden;
        width: 320px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
</style>