import Files from '@/utils/Files'

export default class ExsNotMissingValidator {
    validateExsNotMissing(exs, sounds) {
        let files = new Files()

        if (exs === null || exs.length === 0) {
            throw new Error("exs file missing")
        }
        let exsFilename = files.filename(exs[0])

        if (sounds === null || sounds.length === 0) {
            throw new Error("sounds folder missing")
        }

        let soundsFolder = files.folder(sounds[0])

        if (soundsFolder !== exsFilename) {
            throw new Error(`sounds folder ${soundsFolder} doesn't match to exs ${exsFilename}`)
        }

        for (var i = 0; i < sounds.length; i++) {
            let file = sounds[i]
            let ext = files.extension(file)

            let allowedType = ["wav", "aif"].includes(ext) || files.isHidden(file)
            if (!allowedType) {
                throw new Error(`sounds folder contains unexpected type of file ${ext}`)
            }
        }
    }
}