export default class Files {
    extension(file) {
        let parts = file.name.split(".")
        return parts[parts.length - 1]
    }

    filename(file) {
        return file.name.split(".")[0]
    }

    folder(file) {
        return file.webkitRelativePath.split('/')[0]
    }

    isHidden(file) {
        return file.name.startsWith(".")
    }
}