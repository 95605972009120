<template>
    <div class="list">
        <div>
            <div>
                <router-link :to="`/instruments/${item.id}`">{{ item.name }}</router-link>
                {{ description(item) }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },

    methods: {
        description(item) {
            if (item.description) {
                return "(" + item.description + ")"
            } else {
                return ""
            }
        }
    }
}
</script>

<style scoped></style>