<template>
    <h3>Pack</h3>
    <PackForm 
        v-bind:initial="initial" 
        v-bind:initialProp="initialProp"
        v-bind:initialDelayConfig="initialDelayConfig"
        v-bind:initialReverbConfig="initialReverbConfig"
        v-bind:initialSlotFx1="initialSlotFx1" 
        v-bind:initialSlotFx2="initialSlotFx2" 
        v-bind:initialSlotMelody1="initialSlotMelody1" 
        v-bind:initialSlotMelody2="initialSlotMelody2" 
        v-bind:initialSlotHarmony1="initialSlotHarmony1" 
        v-bind:initialSlotHarmony2="initialSlotHarmony2" 
        v-bind:initialSlotDrums="initialSlotDrums" 
        v-bind:initialSlotBass="initialSlotBass"
        v-bind:availableTimbres="availableTimbres"
        @submited="$router.push('/packs')" />
</template>

<script>
import { assertExpressionStatement } from '@babel/types';
import axios from 'axios';
import PackForm from '@/packs/components/PackForm.vue';

export default {
    components: {
        PackForm
    },

    data() {
        return {
            initial: {},
            initialProp: {},
            initialDelayConfig: {
                dry_wet_mix: 0,
                time_rate: 1, 
                feedback: 50,
                low_pass_cutoff: 15000,
                to_reverb_level: 0.5
            },
            initialReverbConfig: {
                dry_wet_mix: 0,
                predelay: 60,
                release_time: 2.5,
                damping_frequency: 6000.0
            },
            initialSlotFx1: {},
            initialSlotFx2: {},
            initialSlotMelody1: {},
            initialSlotMelody2: {},
            initialSlotHarmony1: {},
            initialSlotHarmony2: {},
            initialSlotDrums: {},
            initialSlotBass: {},
            availableTimbres: [],
            isLoading: false
        }
    },

    methods: {
        async fetchData() {
            try {
                const resDetails = (await axios.get(`/packs/${this.$route.params.id}`)).data.data
                const resTimbres = (await axios.get(`/instruments`)).data.data
                    .map(function (timbre) {
                        return timbre.name
                    })

                // prepare root key
                var initialProp = resDetails.initial_prop
                Object.assign(this.initialProp, initialProp)

                // prepare delay config
                var initialDelayConfig = resDetails.delay_config
                Object.assign(this.initialDelayConfig, initialDelayConfig)

                // prepare reverb config
                var initialReverbConfig = resDetails.reverb_config
                Object.assign(this.initialReverbConfig, initialReverbConfig)

                // prepare tags
                resDetails.tags = resDetails.tags.join()
                Object.assign(this.initial, resDetails)

                // prepare slot Fx1
                var slotFx1 = resDetails.slots.fx_1
                slotFx1.timbres = slotFx1.timbres.join()
                Object.assign(this.initialSlotFx1, slotFx1)

                // prepare slot Fx2
                var slotFx2 = resDetails.slots.fx_2
                slotFx2.timbres = slotFx2.timbres.join()
                Object.assign(this.initialSlotFx2, slotFx2)

                // prepare slot Melody1
                var slotMelody1 = resDetails.slots.melody_1
                slotMelody1.timbres = slotMelody1.timbres.join()
                Object.assign(this.initialSlotMelody1, slotMelody1)

                // prepare slot Melody2
                var slotMelody2 = resDetails.slots.melody_2
                slotMelody2.timbres = slotMelody2.timbres.join()
                Object.assign(this.initialSlotMelody2, slotMelody2)

                // prepare slot Harmony1
                var slotHarmony1 = resDetails.slots.harmony_1
                slotHarmony1.timbres = slotHarmony1.timbres.join()
                Object.assign(this.initialSlotHarmony1, slotHarmony1)

                // prepare slot Harmony2
                var slotHarmony2 = resDetails.slots.harmony_2
                slotHarmony2.timbres = slotHarmony2.timbres.join()
                Object.assign(this.initialSlotHarmony2, slotHarmony2)

                // prepare slot Drums
                var slotDrums = resDetails.slots.drums
                slotDrums.timbres = slotDrums.timbres.join()
                Object.assign(this.initialSlotDrums, slotDrums)

                // prepare slot Bass
                var slotBass = resDetails.slots.bass
                slotBass.timbres = slotBass.timbres.join()
                Object.assign(this.initialSlotBass, slotBass)

                // prepare available timbres
                Object.assign(this.availableTimbres, resTimbres)
            } catch (error) {
                alert(error.message)
            }
        },
    },

    mounted() {
        this.fetchData();
    }
}
</script>