<template>
    <div class="inRow">
        <slot></slot>
        <input v-bind:value="note" 
            @input="noteChanged" 
            type="number" 
            min="0" 
            max="127" 
            step="1" />

        <select v-bind:value="keySelected"
            @change="keyChanged">
            <option v-for="value in keyValues" >{{ value }}</option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        note: {
            type: Number,
            required: false
        },
        keyValues: {
            type: Array,
            required: true
        },
        keySelected: {
            type: String,
            required: false
        }
    },

    methods: {
        noteChanged(event) {
            this.$emit("noteChanged", Number(event.target.value))
        },

        keyChanged(event) {
            this.$emit("keySelected", event.target.value)
        }
    }
}
</script>
