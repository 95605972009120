<template>
    <form class="form" @submit.prevent>
        <div class="slot">
            <div class="column">
                <Textbox
                    @textChanged="(text) => details.title = text"
                    v-bind:text="details.title">
                    Title*
                </Textbox>
                <Select
                    v-bind:values="modes"
                    v-bind:selected="details.mode"
                    @selected="(text) => details.mode = text">
                    Mode
                </Select>
                <Textbox
                    @textChanged="(text) => details.tags = text"
                    v-bind:text="details.tags">
                    Tags (comma-separated)
                </Textbox>
            </div>
            <div class="column">
                <RootKey
                    @keySelected="(key) => prop.key = key"
                    @noteChanged="(note) => prop.note = note"
                    v-bind:keyValues="keys"
                    v-bind:keySelected="prop.key"
                    v-bind:key="prop.key"
                    v-bind:note="prop.note">
                    Root Key
                </RootKey>
                <Float
                    @valueChanged="(value) => prop.bpm = value"
                    v-bind:value="prop.bpm"
                    v-bind:min=0
                    v-bind:step=1
                    v-bind:max=240>
                    <template #label>BPM</template>
                </Float>
            </div>
            <!-- Featured & Badge New -->
            <div class="column">
                <Checkbox 
                    v-bind:checked="details.featured"
                    @toggle="(checked) => details.featured = checked">
                    Featured
                </Checkbox>
                <Checkbox 
                    v-bind:checked="details.badge_new"
                    @toggle="(checked) => details.badge_new = checked">
                    Badge New
                </Checkbox>
                <Number
                    @valueChanged="(value) => details.order = value"
                    v-bind:value="details.order">
                    Order
                </Number>
            </div>
            <!-- Dev Prod Deleted -->
            <div class="column">
                <Checkbox 
                    v-bind:checked="details.dev"
                    @toggle="(checked) => details.dev = checked">
                    Dev
                </Checkbox>
                <Checkbox 
                    v-bind:checked="details.prod"
                    @toggle="(checked) => details.prod = checked">
                    Prod
                </Checkbox>
                <Checkbox 
                    v-bind:checked="details.deleted"
                    @toggle="(checked) => details.deleted = checked">
                    Deleted
                </Checkbox>
            </div>
        </div>
        <!-- DELAY -->
        <!-- REVERB -->
        <div class="slot">
            <div class="column">
                <b>Delay</b>
                <Float
                    @valueChanged="(value) => delayConfig.dry_wet_mix = value"
                    v-bind:value="delayConfig.dry_wet_mix"
                    v-bind:min=0
                    v-bind:max=100
                    v-bind:step=1>
                        <template #label>Dry Wet Mix[0..100]</template>
                        <template #tooltip>Amount of unprocessed (dry) to delayed (wet) audio (Default: 50.0, Disabled: 0))</template>
                </Float>
                <Float
                    @valueChanged="(value) => delayConfig.time_rate = value"
                    v-bind:value="delayConfig.time_rate"
                    v-bind:min=0.1
                    v-bind:max=20
                    v-bind:step=0.1>
                    <template #label>Time rate [0.1..20]</template>
                    <template #tooltip>time = 60 / bpm / rate</template>
                </Float>
                <Float
                    @valueChanged="(value) => delayConfig.feedback = value"
                    v-bind:value="delayConfig.feedback"
                    v-bind:min=0
                    v-bind:max=100
                    v-bind:step=1>
                    <template #label>Feedback [0..100]</template>
                    <template #tooltip>Amount of feedback, ranges from 0 to 100 (Default: 50)</template>
                </Float>
                <Float
                    @valueChanged="(value) => delayConfig.low_pass_cutoff = value"
                    v-bind:value="delayConfig.low_pass_cutoff"
                    v-bind:min=10
                    v-bind:max=22050
                    v-bind:step=10>
                    <template #label>Low-pass cutoff [10..22050]</template>
                    <template #tooltip>Low-pass cutoff frequency in Hz (Default 15000))</template>
                </Float>
                <Float
                    @valueChanged="(value) => delayConfig.to_reverb_level = value"
                    v-bind:value="delayConfig.to_reverb_level"
                    v-bind:min=0
                    v-bind:max=100
                    v-bind:step=1>
                    <template #label>To reverb level [0..100]</template>
                </Float>
            </div>
            <div class="column">
                <b>Reverb</b>
                <Float
                    @valueChanged="(value) => reverbConfig.dry_wet_mix = value"
                    v-bind:value="reverbConfig.dry_wet_mix"
                    v-bind:min=0
                    v-bind:max=100
                    v-bind:step=1>
                    <template #label>Dry Wet Mix [0..100]</template>
                    <template #tooltip>0 = all dry, 1 = all wet, (Disabled: 0))</template>
                </Float>
                <Float
                    @valueChanged="(value) => reverbConfig.predelay = value"
                    v-bind:value="reverbConfig.predelay"
                    v-bind:min=10
                    v-bind:max=100
                    v-bind:step=0.1>
                    <template #label>Predelay [10..100]</template>
                    <template #tooltip>Delay in ms before reverberation begins (Default 60.0ms)</template>
                </Float>
                <Float
                    @valueChanged="(value) => reverbConfig.release_time = value"
                    v-bind:value="reverbConfig.release_time"
                    v-bind:min=1
                    v-bind:max=8
                    v-bind:step=0.1>
                    <template #label>Release Time [1..8]</template>
                    <template #tooltip>Time (in seconds) to decay 60db in low/mid-frequency band (Default 2.5s)</template>
                </Float>
                <Float
                    @valueChanged="(value) => reverbConfig.damping_frequency = value"
                    v-bind:value="reverbConfig.damping_frequency"
                    v-bind:min=1500
                    v-bind:max=47040
                    v-bind:step=10>
                    <template #label>Damping Frequency [1500..47070]</template>
                    <template #tooltip>Frequency (Hz) at which the high-frequency T60 is half the middle-band's T60 (Default 6000)</template>
                </Float>
            </div>
        </div>
        <!-- FX SLOTS -->
        <div class="slot">
            <!-- Fx 1 Block -->
            <div class="column">
                <b>Fx 1</b>
                <Textbox
                    @textChanged="(text) => slotFx1.name = text"
                    v-bind:text="slotFx1.name">
                    Name*
                </Textbox>
                <Textbox
                    @textChanged="(text) => slotFx1.timbres = text"
                    v-bind:text="slotFx1.timbres">
                    Timbres (comma-separated)
                </Textbox>
            </div>
            <!-- Fx 2 Block -->
            <div class="column">
                <b>Fx 2</b>
                <Textbox
                    @textChanged="(text) => slotFx2.name = text"
                    v-bind:text="slotFx2.name">
                    Name*
                </Textbox>
                <Textbox
                    @textChanged="(text) => slotFx2.timbres = text"
                    v-bind:text="slotFx2.timbres">
                    Timbres (comma-separated)
                </Textbox>
            </div>
        </div>
        <!-- MELODY SLOTS -->
        <div class="slot">
            <!-- Melody 1 Block -->
            <div class="column">
                <b>Melody 1</b>
                <Textbox
                    @textChanged="(text) => slotMelody1.name = text"
                    v-bind:text="slotMelody1.name">
                    Name*
                </Textbox>
                <Textbox
                    @textChanged="(text) => slotMelody1.timbres = text"
                    v-bind:text="slotMelody1.timbres">
                    Timbres (comma-separated)
                </Textbox>
            </div>
            <!-- Melody 2 Block -->
            <div class="column">
                <b>Melody 2</b>
                <Textbox
                    @textChanged="(text) => slotMelody2.name = text"
                    v-bind:text="slotMelody2.name">
                    Name*
                </Textbox>
                <Textbox
                    @textChanged="(text) => slotMelody2.timbres = text"
                    v-bind:text="slotMelody2.timbres">
                    Timbres (comma-separated)
                </Textbox>
            </div>
        </div>
        <!-- HARMONY SLOTS -->
        <div class="slot">
            <!-- Harmony 1 Block -->
            <div class="column">
                <b>Harmony 1</b>
                <Textbox
                    @textChanged="(text) => slotHarmony1.name = text"
                    v-bind:text="slotHarmony1.name">
                    Name*
                </Textbox>
                <Textbox
                    @textChanged="(text) => slotHarmony1.timbres = text"
                    v-bind:text="slotHarmony1.timbres">
                    Timbres (comma-separated)
                </Textbox>
            </div>
            <!-- Harmony 2 Block -->
            <div class="column">
                <b>Harmony 2</b>
                <Textbox
                    @textChanged="(text) => slotHarmony2.name = text"
                    v-bind:text="slotHarmony2.name">
                    Name*
                </Textbox>
                <Textbox
                    @textChanged="(text) => slotHarmony2.timbres = text"
                    v-bind:text="slotHarmony2.timbres">
                    Timbres (comma-separated)
                </Textbox>
            </div>
        </div>

        <!-- DRUMS & BASS SLOTS -->
        <div class="slot">
            <!-- Drums Slot -->
            <div class="column">
                <b>Drums</b>
                <Textbox
                    @textChanged="(text) => slotDrums.timbres = text"
                    v-bind:text="slotDrums.timbres">
                    Timbres (comma-separated)
                </Textbox>
            </div>
            <!-- Bass Slot -->
            <div class="column">
                <b>Bass</b>
                <Textbox
                    @textChanged="(text) => slotBass.timbres = text"
                    v-bind:text="slotBass.timbres">
                    Timbres (comma-separated)
                </Textbox>
            </div>
        </div>
        <div class="newItemField">
            MIDI files
            <input
                ref="inputFiles"
                type="file" 
                accept=".wav,.aif"
                webkitdirectory directory multiple/>
        </div>
        <div class="newItemField">
            Cover
            <input
                ref="inputCover"
                type="file" 
                accept=".jpg"/>
        </div>
        <div class="newItemField">
            Preview
            <input
                ref="inputPreview"
                type="file" 
                accept=".m4a"/>
        </div>
        <div class="newItemField">
            Demo
            <input
                ref="inputDemo"
                type="file" 
                accept=".json"/>
        </div>

        <div style="display:flex; align-items: left;">
            <Button 
                @click="uploadAndCreate"
                style="margin-top: 15px;">
                Submit
            </Button>
            <div v-if="isLoading"><p>Loading...</p></div>
        </div>
    </form>
</template>

<script>
import PackUploader from '@/packs/utils/PackUploader'
import PackZip from '@/packs/utils/PackZip'
import PackSender from '@/packs/utils/PackSender'
import Button from '@/components/Button.vue'
import Checkbox from '@/components/Checkbox.vue'
import Textbox from '@/components/Textbox.vue'
import Number from '@/components/Number.vue'
import Float from '@/components/Float.vue'
import RootKey from '@/components/RootKey.vue'
import PackComposer from '@/packs/utils/validators/PackComposer'
import FxSlotComposer from '@/packs/utils/validators/FxSlotComposer'
import MelodySlotComposer from '@/packs/utils/validators/MelodySlotComposer'
import HarmonySlotComposer from '@/packs/utils/validators/HarmonySlotComposer'
import DrumsSlotComposer from '@/packs/utils/validators/DrumsSlotComposer'
import BassSlotComposer from '@/packs/utils/validators/BassSlotComposer'
import PackFilesValidator from '@/packs/utils/validators/PackFilesValidator'
import DelayConfigValidator from '@/packs/utils/validators/DelayConfigValidator'
import ReverbConfigValidator from '@/packs/utils/validators/ReverbConfigValidator'
import TimbresValidator from '@/packs/utils/validators/TimbresValidator'
import Select from '@/components/Select.vue'

export default {
    components: {
        Button, Checkbox, Textbox, Number, Float, Select, RootKey
    },

    props: {
        initial: {
            type: Object,
            required: false
        },
        initialProp: {
            type: Object,
            required: false
        },
        initialDelayConfig: {
            type: Object,
            required: false
        },
        initialReverbConfig: {
            type: Object,
            required: false
        },
        initialSlotFx1: {
            type: Object,
            required: false
        },
        initialSlotFx2: {
            type: Object,
            required: false
        },
        initialSlotMelody1: {
            type: Object,
            required: false
        },
        initialSlotMelody2: {
            type: Object,
            required: false
        },
        initialSlotHarmony1: {
            type: Object,
            required: false
        },
        initialSlotHarmony2: {
            type: Object,
            required: false
        },
        initialSlotDrums: {
            type: Object,
            required: false
        },
        initialSlotBass: {
            type: Object,
            required: false
        },
        availableTimbres: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            modes: ["free", "ads", "pro"],
            keys: ["major", "minor", "major_blues", "major_VII_flat", "phrygian_phonk"],
            details: this.initial,
            prop: this.initialProp,
            delayConfig: this.initialDelayConfig,
            reverbConfig: this.initialReverbConfig,
            slotFx1: this.initialSlotFx1,
            slotFx2: this.initialSlotFx2,
            slotMelody1: this.initialSlotMelody1,
            slotMelody2: this.initialSlotMelody2,
            slotHarmony1: this.initialSlotHarmony1,
            slotHarmony2: this.initialSlotHarmony2,
            slotDrums: this.initialSlotDrums,
            slotBass: this.initialSlotBass,
            isLoading: false
        }
    },

    methods: {
        async uploadAndCreate() {
            this.isLoading = true

            try {
                let details = this.details
                var toPost = (new PackComposer()).compose(
                    details.title,
                    details.mode,
                    details.tags,
                    details.dev,
                    details.prod,
                    details.deleted,
                    details.featured,
                    details.badge_new,
                    details.order,
                    details.image_url,
                    details.preview_url,
                    details.demo
                )

                toPost.initial_prop = this.composeProp()
                toPost.delay_config = this.composeDelayConfig()
                toPost.reverb_config = this.composeReverbConfig()

                toPost.slots = {
                    fx_1: this.composeSlotWithName(this.slotFx1, new FxSlotComposer("Fx 1")),
                    fx_2: this.composeSlotWithName(this.slotFx2, new FxSlotComposer("Fx 2")),
                    melody_1: this.composeSlotWithName(this.slotMelody1, new MelodySlotComposer("Melody 1")),
                    melody_2: this.composeSlotWithName(this.slotMelody2, new MelodySlotComposer("Melody 2")),
                    harmony_1: this.composeSlotWithName(this.slotHarmony1, new HarmonySlotComposer("Harmony 1")),
                    harmony_2: this.composeSlotWithName(this.slotHarmony2, new HarmonySlotComposer("Harmony 2")),
                    drums: this.composeSlotWithoutName(this.slotDrums, new DrumsSlotComposer("Drums")),
                    bass: this.composeSlotWithoutName(this.slotBass, new BassSlotComposer("Bass")),
                }

                const updateOptions = this.updateOptions()

                const sender = new PackSender()
                var hash, url, image_url, preview_url, demo
                const uploader = new PackUploader()

                const coverFiles = this.$refs.inputCover.files
                if (coverFiles.length > 0) {
                    const uploadedCover = await uploader.upload(coverFiles[0], 'cover.jpg', 'cover')
                    const coverResJson = uploadedCover.data.data

                    image_url = coverResJson.url
                } else {
                    image_url = this.details.image_url
                }

                const demoFiles = this.$refs.inputDemo.files
                if (demoFiles.length > 0) {
                    const uploadedDemo = await uploader.upload(demoFiles[0], 'demo.json', 'demo')
                    const demoResJson = uploadedDemo.data.data

                    demo = demoResJson.url
                } else {
                    demo = this.details.demo
                }

                const previewFiles = this.$refs.inputPreview.files
                if (previewFiles.length > 0) {
                    const uploadedPreview = await uploader.upload(previewFiles[0], 'preview.m4a', 'preview')
                    const previewResJson = uploadedPreview.data.data

                    preview_url = previewResJson.url
                } else {
                    preview_url = this.details.preview_url
                }

                const files = this.$refs.inputFiles.files
                const validator = new PackFilesValidator()
                validator.validate(files, updateOptions)
                if (files.length > 0) {
                    const zip = new PackZip()
                    const compressed = await zip.zip(files)

                    const uploadedPack = await uploader.upload(compressed, 'pack.zip', '')
                    const packResJson = uploadedPack.data.data

                    hash = packResJson.hash
                    url = packResJson.url
                } else {
                    hash = this.details.hash
                    url = this.details.url
                }

                toPost.hash = hash
                toPost.url = url
                toPost.image_url = image_url
                toPost.preview_url = preview_url
                toPost.demo = demo

                const result = await sender.send(toPost, updateOptions)
                console.log("success", result)
                alert("Success")
                this.$emit('submited')
            } catch (error) {
                alert(error)
            } finally {
                this.isLoading = false
            }
        },

        updateOptions() {
            let id = this.initial.id
            return (id === null | id === undefined) ? null : { id: id }
        },

        composeProp() {
            let composed = {
                key: this.prop.key,
                note: this.prop.note,
                bpm: this.prop.bpm
            }

            return composed
        },

        composeDelayConfig() {
            let dryWet = this.delayConfig.dry_wet_mix
            let timeRate = this.delayConfig.time_rate
            let feedback = this.delayConfig.feedback
            let lowPass = this.delayConfig.low_pass_cutoff
            let toReverb = this.delayConfig.to_reverb_level

            if (dryWet === 0) {
                return null
            }

            let delayValidator = new DelayConfigValidator()
            delayValidator.validate(dryWet, feedback, timeRate, lowPass, toReverb)

            let composed = {
                dry_wet_mix: dryWet,
                feedback: feedback,
                time_rate: timeRate,
                low_pass_cutoff: lowPass,
                to_reverb_level: toReverb
            }

            return composed
        },

        composeReverbConfig() {
            let dryWet = this.reverbConfig.dry_wet_mix
            let predelay = this.reverbConfig.predelay
            let releaseTime = this.reverbConfig.release_time
            let dampingFrequency = this.reverbConfig.damping_frequency

            if (dryWet === 0) {
                return null
            }

            let reverbValidator = new ReverbConfigValidator()
            reverbValidator.validate(dryWet, predelay, releaseTime, dampingFrequency)

            let composed = {
                dry_wet_mix: dryWet,
                predelay: predelay,
                release_time: releaseTime,
                damping_frequency: dampingFrequency
            }

            return composed
        },

        composeSlotWithName(slot, composer) {
            let composed = composer.compose(
                slot.name,
                slot.timbres
            )

            let timbresValidator = new TimbresValidator(composer.label)
            timbresValidator.validate(composed.timbres, this.availableTimbres)

            return composed
        },

        composeSlotWithoutName(slot, composer) {
            let composed = composer.compose(
                slot.timbres
            )

            let timbresValidator = new TimbresValidator(composer.label)
            timbresValidator.validate(composed.timbres, this.availableTimbres)

            return composed
        }
    }
}
</script>

<style scoped>
    .form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .column {
        display: flex;
        flex-direction: column;
        border: 2px solid teal;
        padding: 12px;
        /* justify-content: space-between; */
    }

    .slot {
        display: flex;
        align-items: left;
        gap: 12px;
    }

    .newItemField {
        padding: 8px;
    }
</style>