<template>
    <h3>Packs</h3>
    <Button @click="$router.push('/packs/new')"
        style="margin-bottom: 15px;">
        Create New
    </Button>
    <PackList v-bind:list="list" />
</template>

<script>
import axios from 'axios';
import PackForm from '@/packs/components/PackForm.vue';
import PackList from '@/packs/components/PackList.vue';
import Button from '@/components/Button.vue';

export default {
    components: {
        PackList, PackForm, Button
    },

    data() {
        return {
            list: [],
            initial: {},
            initialSlotHarmony1: {},
            initialSlotHarmony2: {},
            initialSlotDrums: {},
            initialSlotBass: {},
            isLoading: false
        }
    },

    methods: {
        async fetchPacks() {
            try {
                const resPacks = (await axios.get('/packs?all=true')).data.data
                this.list = resPacks.sort(this.compare)
            } catch (error) {
                alert(error.message)
            }
        },

        compare(a, b) {
            if (!a.deleted & b.deleted) {
                return -1
            }

            if (a.deleted & !b.deleted) {
                return 1
            }

            if (a.order === b.order) {
                return b.timestamp - a.timestamp
            }

            return a.order - b.order
        }
    },

    mounted() {
        this.fetchPacks();
    }
}
</script>

<style></style>