import JSZip from 'jszip';

export default class InstrumentZip {
    async zip(exs, sounds, soundsFolder) {
        // Assign new zip object
        let zip = new JSZip()
    
        zip.folder("Sampler Instruments")
            .file(exs.name, exs)

        let soundsZipFolder = zip.folder(soundsFolder)
        for(let sound of sounds) {
            let filename = sound.name
            if (filename.startsWith(".")) {
                // skip hidden files like .DS_Store
                continue;
            }

            soundsZipFolder.file(filename, sound)
        }
    
        // Generate the complete zip file
        let compressed = await zip.generateAsync({
            type:'blob',
            compression: "DEFLATE",
            compressionOptions: { level: 9 }
        })
    
        return compressed
    }    
}
