export default class ReverbConfigValidator {
    validate(dryWetMix, predelay, releaseTime, dampingFrequency) {
        if (!this.between(dryWetMix, 0, 100)) {
            throw new Error("dryWetMix is out of range")
        }

        if (!this.between(predelay, 10, 100)) {
            throw new Error("predelay is out of range")
        }

        if (!this.between(releaseTime, 1, 8)) {
            throw new Error("releaseTime is out of range")
        }

        if (!this.between(dampingFrequency, 1500, 47040)) {
            throw new Error("dampingFrequency is out of range")
        }

        return true
    }

    between(x, min, max) {
        return x >= min && x <= max;
    }
}