<template>
    <div class="inRow">
        <slot></slot>
        <input
            v-bind:value="value"
            @input="changed"
            type="number"
            min="0"
            step="10"/>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: false,
            default: 0
        }
    },

    methods: {
        changed(event) {
            this.$emit("valueChanged", Number(event.target.value))
        }
    }
}
</script>
