import CommaSeparatedSplitter from "../composers/CommaSeparatedSplitter";

export default class FxSlotComposer {
    constructor(label) {
        this.label = label
    }

    compose(name, timbres) {
        if (name === null | name === '' | name === undefined) {
            throw Error(`[${this.label}] name missing`)
        }

        const timbresArray = (new CommaSeparatedSplitter().split(this.string(timbres)))
        if (timbresArray.length == 0) {
            throw Error(`[${this.label}] at least 1 timbre must be specified`)
        }

        var compose = {
            name: name,
            timbres: timbresArray
        }

        return compose
    }

    string(value) {
        return value === undefined ? '' : value
    }
}