<template>
    <h3>Instrument</h3>
    <InstrumentForm 
        v-bind:initial="initial"
        @submited="$router.push('/instruments')" />
</template>

<script>
import { assertExpressionStatement } from '@babel/types';
import axios from 'axios';
import InstrumentForm from '@/instruments/components/InstrumentForm.vue';

export default {
    components: {
        InstrumentForm
    },

    data() {
        return {
            initial: {
                delay_level: 0,
                reverb_level: 0
            },
            isLoading: false
        }
    },

    methods: {
        async fetchData() {
            try {
                const resDetails = (await axios.get(`/instruments/${this.$route.params.id}`)).data.data

                Object.assign(this.initial, resDetails)
                this.initial._name = resDetails.name
            } catch (error) {
                alert(error.message)
            }
        },
    },

    mounted() {
        this.fetchData();
    }
}
</script>
