<template>
    <p>
        <router-link to="/packs">Packs</router-link> |
        <router-link to="/instruments">Instruments</router-link> |
        <router-link to="/settings">Settings</router-link>
    </p>
    <h2>Music Maker Admin</h2>
    <router-view></router-view>
</template>

<script>
export default {

}
</script>

<style>
* {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
} 

.inRow {
    padding: 8px;
    display: flex;
    gap: 8px;
}
</style>