export default class PackFilesValidator {
    validate(files, updating) {
        let noFiles = (files === undefined | files === null | files.length === 0)

        if (updating !== null & noFiles) {
            return
        }

        const allowed = [
            "fx1 pilot.mid",
            "fx1 patterns.mid",
            "fx2 pilot.mid",
            "fx2 patterns.mid",
            "melody1 pilot.mid",
            "melody1 patterns.mid",
            "melody2 pilot.mid",
            "melody2 patterns.mid",
            "harmony1 pilot.mid",
            "harmony1 patterns.mid",
            "harmony2 pilot.mid",
            "harmony2 patterns.mid",
            "bass pilot.mid",
            "bass patterns.mid",
            "drums patterns.mid"
        ]

        const optional = [
            "fx1 pilot.mid",
            "fx2 pilot.mid",
            "melody1 pilot.mid",
            "melody2 pilot.mid",
            "harmony1 pilot.mid",
            "harmony2 pilot.mid",
            "bass pilot.mid"
        ]
        
        var filenames = []
        for (let file of files) {
            filenames.push(file.name)
        }

        for (let filename of filenames) {
            if (filename === ".DS_Store") {
                continue
            }

            if (!allowed.includes(filename)) {
                throw Error(`File ${filename} is not allowed`)
            }
        }

        let missing = allowed.filter(function (filename) {
            const miss = !filenames.includes(filename)
            const opt = optional.includes(filename)

            return miss && !opt
        })

        if (missing.length > 0) {
            throw Error(`Files ${missing} missing`)
        }
    }
}