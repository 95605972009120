<template>
    <InstrumentItem
        v-for="item in list"
        v-bind:item="item"
    /> 
</template>

<script>
import InstrumentItem from './InstrumentItem.vue';

export default {
    components: {
        InstrumentItem
    },

    props: {
        list: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped></style>