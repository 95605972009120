<template>
    <div class="inRow">
        <slot></slot>
        <input
            v-bind:value="text"
            @input="changed"
            type="text"/>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: false
        }
    },

    methods: {
        changed(event) {
            this.$emit("textChanged", event.target.value)
        }
    }
}
</script>
