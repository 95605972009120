<template>
    <div class="inRow">
        <slot></slot>
        <input
            v-bind:checked="checked"
            @input="changed"
            type="checkbox"/>
    </div>
</template>

<script>
export default {
    props: {
        checked: {
            type: Boolean,
            required: false
        }
    },

    methods: {
        changed(event) {
            this.$emit("toggle", event.target.checked)
        }
    }
}
</script>
