import Files from '@/utils/Files'

export default class ExsUniqueValidator {
    validateExsUnique(exs, sounds, existing) {
        let files = new Files()

        let exsFilename = files.filename(exs[0])
        let soundsFolder = files.folder(sounds[0])

        for (var i = 0; i < existing.length; i++) {
            let name = existing[i].name
            let file = existing[i].url.split("/").pop().replace(".zip", "")

            if (file === exsFilename) {
                throw new Error(`exs filename with the name '${exsFilename}' already exists in instrument '${name}'`)
            }

            if (file === soundsFolder) {
                throw new Error(`sounds folder with the name '${soundsFolder}' already exists in instrument '${name}'`)
            }
        }
    }
}