import FormData from 'form-data'
import axios from 'axios'
import Auth from '@/settings/Auth'

export default class PackUploader {
    async upload(file, filename, resource) {
        var form = new FormData();
        form.append('file', file, filename);

        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': Auth.header()
        }

        return await axios.post(
            `/packs/upload/${resource}`,
            form,
            { headers: headers }
        )
    }
}
