<template>
    <h3>Instruments</h3>
    <Button 
        @click="$router.push('/instruments/new')" 
        style="margin-bottom: 15px;">
        Create New
    </Button>
    <InstrumentList v-bind:list="list"/>
</template>

<script>
import axios from 'axios';
import InstrumentList from '@/instruments/components/InstrumentList.vue';
import Button from '@/components/Button.vue';

export default {
    components: {
        InstrumentList, Button
    },

    data() {
        return {
            list: [],
        }
    },

    methods: {
        async fetchInstruments() {
            try {
                const res = await axios.get('/instruments');
                const body = res.data

                this.list = body.data.sort(this.compare)
            } catch (error) {
                alert(error.message)
            }
        },

        compare(a, b) {
            const aTimestamp = a.timestamp ?? 0
            const bTimestamp = b.timestamp ?? 0

            return bTimestamp - aTimestamp
        }
    },

    mounted() {
        this.fetchInstruments();
    }
}
</script>
