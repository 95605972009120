<template>
    <div class="list">
        <div class="row">
            <div>
                <div v-if=item.deleted style="text-decoration: line-through">
                    <router-link :to="`/packs/${item.id}`">{{ item.title }}</router-link>
                </div>
                <div v-else-if=!item.deleted>
                    <router-link :to="`/packs/${item.id}`">{{ item.title }}</router-link>
                </div>
            </div>
            <img v-if=item.badge_new title="badge new" src="@/../public/new.svg" class="icon" />
            <img v-if=item.featured title="featured" src="@/../public/featured.svg" class="icon" />
            <img v-if=item.dev title="dev" src="@/../public/dev.svg" class="icon" />
            <img v-if=item.prod title="prod" src="@/../public/prod.svg" class="icon" />
            <a v-if=item.image_url v-bind:href="`${item.image_url}`" target="_blank">
                <img title="cover" src="@/../public/cover.svg" class="icon" />
            </a>
            <a v-if=item.preview_url v-bind:href="`${item.preview_url}`" target="_blank">
                <img title="preview" src="@/../public/preview.svg" class="icon" />
            </a>
            <a v-if=item.demo v-bind:href="`${item.demo}`" target="_blank">
                <img title="demo" src="@/../public/demo.svg" class="icon">
            </a>
            <div class="order" title="order">
                {{ item.order }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },

    methods: {
    }
}
</script>

<style scoped>
    .row {
        display: flex;
        align-items: left;
        height: 24px;
    }
    
    .order {
        color: lightgrey;
        padding-left: 6px;
    }

    .icon {
        height: 20px;
        padding-left: 6px;
    }
</style>