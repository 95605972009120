export default class ReverbLevelValidator {
    validate(reverbLevel) {
        if (!this.between(reverbLevel, 0, 100)) {
            throw new Error("reverb level is out of range")
        }

        return true
    }

    between(x, min, max) {
        return x >= min && x <= max;
    }
}