<template>
    <form @submit.prevent>
        <Textbox
            @textChanged="(text) => details.name = text"
            v-bind:text="details.name">
            Name*
        </Textbox>
        <Textbox
            @textChanged="(text) => details.description = text"
            v-bind:text="details.description">
            Description
        </Textbox>
        <Float
            @valueChanged="(value) => details.delay_level = value"
            v-bind:value="details.delay_level"
            v-bind:min=0
            v-bind:max=100
            v-bind:step=1>
            <template #label>Delay level [0..100]</template>
        </Float>
        <Float
            @valueChanged="(value) => details.reverb_level = value"
            v-bind:value="details.reverb_level"
            v-bind:min=0
            v-bind:max=100
            v-bind:step=1>
            <template #label>Reverb level [0..100]</template>
        </Float>
        <div class="newItemField">
            EXS File
            <input
                ref="inputExs"
                type="file"
                accept=".exs"/>
        </div>
        <div class="newItemField">
            Sounds Folder
            <input
                ref="inputSounds"
                type="file" 
                accept=".wav,.aif"
                webkitdirectory directory multiple/>
        </div>

        <div class="newItemField">
            <Button 
                @click="uploadAndCreate"
                style="margin-top: 15px;">
                Submit
            </Button>
            <div class="inline-child" v-if="isLoading"><p>Loading...</p></div>
        </div>
    </form>
</template>

<script>
import InstrumentUploader from '@/instruments/utils/InstrumentUploader'
import InstrumentZip from '@/instruments/utils/InstrumentZip'
import Files from '@/utils/Files'
import InstrumentSender from '@/instruments/utils/InstrumentSender'
import Button from '@/components/Button.vue'
import Float from '@/components/Float.vue'
import Textbox from '@/components/Textbox.vue'
import UpdateInstrumentValidator from '../utils/validators/UpdateInstrumentValidator'
import CreateInstrumentValidator from '../utils/validators/CreateInstrumentValidator'

export default {
    components: {
        Button, Float, Textbox
    },

    props: {
        initial: {
            type: Object,
            required: false
        },
        availableInstruments: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            details: this.initial,
            isLoading: false
        }
    },

    methods: {
        async uploadAndCreate() {
            this.isLoading = true

            try {
                let instrumentId = (this.initial.id === undefined) ? null : this.initial.id
                let isUpdate = instrumentId !== null

                let exs = this.$refs.inputExs.files
                let sounds = this.$refs.inputSounds.files

                var uploadNeeded
                var options
                if (isUpdate) {
                    options = { id: instrumentId }

                    let validator = new UpdateInstrumentValidator()
                    uploadNeeded = validator.validate(this.details.name, this.details._name, exs, sounds, this.details.delay_level, this.details.reverb_level)
                } else {
                    options = null

                    let validator = new CreateInstrumentValidator()
                    uploadNeeded = validator.validate(this.details.name, exs, sounds, this.availableInstruments, this.details.delay_level, this.details.reverb_level)
                }

                let sender = new InstrumentSender()
                var hash, url
                if (uploadNeeded) {
                    let files = new Files()
                    let exsFilename = files.filename(exs[0])
                    let soundsFolder = files.folder(sounds[0])

                    let zip = new InstrumentZip()
                    let compressed = await zip.zip(exs[0], sounds, soundsFolder)

                    let uploader = new InstrumentUploader()
                    let uploaded = await uploader.upload(compressed, `${exsFilename}.zip`)
                    let resJson = uploaded.data.data

                    hash = resJson.hash
                    url = resJson.url
                } else {
                    hash = this.details.hash
                    url = this.details.url
                }

                let result = await sender.send(this.details.name, this.details.description, this.details.delay_level, this.details.reverb_level, url, hash, options)
                console.log("success", result)
                alert("Success")
                this.$emit('submited')
            } catch (error) {
                alert(error)
            } finally {
                this.isLoading = false
            }
        }
    }
}
</script>

<style scoped>
    .form {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .newItemField {
        padding: 8px;
    }

    .inline-child {
        margin-right: 8px;
        display: inline-block;
    }
</style>