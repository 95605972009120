<template>
    <button class="uploadButton inline-child" @click="uploadAndCreate">
        <slot></slot>
    </button>
</template>

<script>

export default {
}
</script>

<style scoped>
.uploadButton {
    background: none;
    color: teal;
    border: 2px solid teal;
    padding: 8px;
    font-weight: bold;
}

.inline-child {
    margin-right: 8px;
    display: inline-block;
}
</style>