import axios from 'axios'
import Auth from '@/settings/Auth'

export default class InstrumentSender {
    async send(name, description, delayLevel, reverbLevel, url, hash, update) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': Auth.header()
        }

        const body = {
            'name': name,
            'delay_level': delayLevel,
            'reverb_level': reverbLevel,
            'description': description,
            'url': url,
            'hash': hash
        }

        var res
        if (update) {
            res = await axios.put(
                `/instruments/${update.id}`,
                body,
                { headers: headers }
            )
        } else {
            res = await axios.post(
                `/instruments/`,
                body,
                { headers: headers }
            )
        }

        return res
    }
}
