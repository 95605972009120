import JSZip from 'jszip';

export default class PackZip {
    async zip(toUpload) {
        // Assign new zip object
        let zip = new JSZip()

        for (let file of toUpload) {
            let filename = file.name
            if (filename.startsWith(".")) {
                // skip hidden files like .DS_Store
                continue;
            }

            zip.file(filename, file)
        }

        // Generate the complete zip file
        let compressed = await zip.generateAsync({
            type: 'blob',
            compression: "DEFLATE",
            compressionOptions: { level: 9 }
        })

        return compressed
    }
}
