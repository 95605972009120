<template>
    <div class="inRow">
        <slot></slot>
        <select v-bind:value="selected" @change="changed">
            <option v-for="value in values" v-bind:value="value">{{ value }}</option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        values: {
            type: Array,
            required: true
        },

        selected: {
            type: String,
            required: false
        }
    },

    methods: {
        changed(event) {
            this.$emit("selected", event.target.value)
        }
    }
}
</script>
