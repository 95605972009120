<template>
    <h3>Create Instrument</h3>
    <InstrumentForm 
        v-bind:initial="initial"
        v-bind:availableInstruments="availableInstruments"
        @submited="$router.push('/instruments')" />
</template>

<script>
import axios from 'axios';
import InstrumentForm from '@/instruments/components/InstrumentForm.vue';

export default {
    components: {
        InstrumentForm
    },

    data() {
        return {
            initial: {
                delay_level: 0,
                reverb_level: 0
            },
            availableInstruments: [],
            isLoading: false
        }
    },

    methods: {
        async fetchInstruments() {
            const resInstruments = (await axios.get(`/instruments`)).data.data

            // prepare available timbres
            Object.assign(this.availableInstruments, resInstruments)
        },
    },

    mounted() {
        this.fetchInstruments();
    }
}
</script>
