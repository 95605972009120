<template>
    <PackItem v-for="item in list" v-bind:item="item" />
</template>

<script>
import PackItem from './PackItem.vue';

export default {
    components: {
        PackItem
    },

    props: {
        list: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped></style>