import { createRouter, createWebHashHistory } from "vue-router";
import Access from './settings/Settings.vue'
import Instruments from "./instruments/Instruments.vue"
import CreateInstrument from "./instruments/CreateInstrument.vue"
import Packs from "./packs/Packs.vue"
import CreatePack from "./packs/CreatePack.vue"
import PackDetails from "./packs/PackDetails.vue"
import InstrumentDetails from "./instruments/InstrumentDetails.vue"
import Auth from '@/settings/Auth'

const routes = [
    { path: '/', component: Packs, meta: { needAuth: true } },
    { path: '/packs', component: Packs, meta: { needAuth: true } },
    { path: '/packs/new', component: CreatePack, meta: { needAuth: true } },
    { path: '/packs/:id', component: PackDetails, meta: { needAuth: true } },
    { path: '/instruments', component: Instruments, meta: { needAuth: true }},
    { path: '/instruments/new', component: CreateInstrument, meta: { needAuth: true }},
    { path: '/instruments/:id', component: InstrumentDetails, meta: { needAuth: true }},
    { path: '/settings', name: "Settings", component: Access, meta: { needAuth: false } }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach(function (to, from, next) {
    const isAuthorized = Auth.isAuthorized()

    if (to.meta.needAuth && !isAuthorized) {
        alert("You are not logged in")
        next({ name: 'Settings' })
    } else {
        next()
    }
})

export default router