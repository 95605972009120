import CommaSeparatedSplitter from "../composers/CommaSeparatedSplitter";

export default class PackComposer {
    compose(title, mode, tags, dev, prod, deleted, featured, badgeNew, order, image_url, preview_url, demo) {
        if (title === null | title === '' | title === undefined) {
            throw Error('title is missing')
        }

        const allowedModes = ["free", "ads", "pro"]
        if (allowedModes.includes(mode) === false) {
            throw Error('mode is invalid')
        }

        const tagsArray = (new CommaSeparatedSplitter().split(this.string(tags)))

        return {
            title: title,
            mode: mode,
            tags: tagsArray,
            dev: this.bool(dev),
            prod: this.bool(prod),
            deleted: this.bool(deleted),
            featured: this.bool(featured),
            badge_new: this.bool(badgeNew),
            image_url: image_url,
            preview_url: preview_url,
            demo: demo,
            order: Number(order)
        }
    }

    bool(value) {
        return value === undefined ? false : value
    }

    string(value) {
        return value === undefined ? '' : value
    }
}