<template>
    <h3>Create pack</h3>
        <PackForm 
            v-bind:initial="initial"
            v-bind:initialProp="initialProp"
            v-bind:initialDelayConfig="initialDelayConfig"
            v-bind:initialReverbConfig="initialReverbConfig"
            v-bind:initialSlotFx1="initialSlotFx1"
            v-bind:initialSlotFx2="initialSlotFx2"
            v-bind:initialSlotMelody1="initialSlotMelody1"
            v-bind:initialSlotMelody2="initialSlotMelody2"
            v-bind:initialSlotHarmony1="initialSlotHarmony1"
            v-bind:initialSlotHarmony2="initialSlotHarmony2"
            v-bind:initialSlotDrums="initialSlotDrums"
            v-bind:initialSlotBass="initialSlotBass"
            v-bind:availableTimbres="availableTimbres"
            @submited="$router.push('/packs')" />
</template>

<script>
import PackForm from '@/packs/components/PackForm.vue';
import axios from 'axios';

export default {
    components: {
        PackForm
    },

    data() {
        return {
            list: [],
            initial: {
                mode: "free",
                dev: true,
                badge_new: true,
                order: 0
            },
            initialProp: {
                key: "major",
                note: 60,
                bpm: 90
            },
            initialDelayConfig: {
                dry_wet_mix: 0,
                feedback: 50,
                time_rate: 1, 
                low_pass_cutoff: 15000,
                to_reverb_level: 0.5
            },
            initialReverbConfig: {
                dry_wet_mix: 0,
                predelay: 60,
                release_time: 2.5,
                damping_frequency: 6000.0
            },
            initialSlotFx1: {},
            initialSlotFx2: {},
            initialSlotMelody1: {},
            initialSlotMelody2: {},
            initialSlotHarmony1: {},
            initialSlotHarmony2: {},
            initialSlotDrums: {},
            initialSlotBass: {},
            availableTimbres: [],
            isLoading: false
        }
    },

    methods: {
        async fetchTimbres() {
            const resTimbres = (await axios.get(`/instruments`)).data.data
                .map(function(timbre) {
                    return timbre.name
                })

            // prepare available timbres
            Object.assign(this.availableTimbres, resTimbres)
        }
    },

    mounted() {
        this.fetchTimbres()
    }
}
</script>

<style></style>