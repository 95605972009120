import ExsNotMissingValidator from './ExsNotMissingValidator'
import DelayLevelValidator from './DelayLevelValidator'
import ReverbLevelValidator from './ReverbLevelValidator'

export default class UpdateInstrumentValidator {
    validate(name, prevName, exs, sounds, delayLevel, reverbLevel) {
        if (name !== prevName) {
            throw new Error("the name cannot be changed")
        }

        let delayLevelVal = new DelayLevelValidator()
        delayLevelVal.validate(delayLevel)

        let reverbLevelVal = new ReverbLevelValidator()
        reverbLevelVal.validate(reverbLevel)

        let hasExs = !(exs === null || exs.length === 0)
        let hasSounds = !(sounds === null || sounds.length === 0)

        if (hasExs || hasSounds) {
            let exsVal = new ExsNotMissingValidator()
            exsVal.validateExsNotMissing(exs, sounds)
 
            return true
        }
        
        return false
    }
}