import ExsNotMissingValidator from './ExsNotMissingValidator'
import ExsUniqueValidator from './ExsUniqueValidator'
import DelayLevelValidator from './DelayLevelValidator'
import ReverbLevelValidator from './ReverbLevelValidator'

export default class CreateInstrumentValidator {
    validate(name, exs, sounds, existing, delayLevel, reverbLevel) {
        if (name == null) {
            throw new Error("name is missing")
        }

        let exsVal = new ExsNotMissingValidator()
        exsVal.validateExsNotMissing(exs, sounds)

        let exsUniqueVal = new ExsUniqueValidator()
        exsUniqueVal.validateExsUnique(exs, sounds, existing)
        
        let delayLevelVal = new DelayLevelValidator()
        delayLevelVal.validate(delayLevel)

        let reverbLevelVal = new ReverbLevelValidator()
        reverbLevelVal.validate(reverbLevel)

        return true
    }
}