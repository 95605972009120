import axios from 'axios'

export default class Auth {
    static async auth(token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        const res = await axios.post(
            `/auth`,
            {},
            { headers: headers }
        )

        return res
    }

    static isAuthorized() {
        return Auth.token() !== null
    }

    static token() {
        let token = localStorage.getItem('token')

        return (token !== null && token.length > 0) ? token : null
    }

    static saveToken(token) {
        localStorage.setItem('token', token)
    }

    static header() {
        let token = this.token()

        return token !== null ? `Bearer ${token}` : null
    }

    static removeToken() {
        localStorage.removeItem('token')
    }
}