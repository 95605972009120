import FormData from 'form-data'
import axios from 'axios'
import Auth from '@/settings/Auth'

export default class InstrumentUploader { 
    async upload(zip, filename) {
        var form = new FormData();
        form.append('file', zip, filename); 

        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': Auth.header()
        }

        return await axios.post(
            `/instruments/upload`,
            form,
            { headers: headers }
        )
    }
}
